// ------ COlor
$white: #fff;
$black: #000000;
$grey: #ddd;
$gray: #dfd9e2;
$grey-light: #f3f3f5;
$red: #d1462f;
$pink: #e8e8e3;
$yellow1: #f5d300;
$yellow2: #f2cd60;
$blue: #202734;
$purple: #8f82f9;
$navy: #652ec7;
$bg-color: #fff;
$bg-img: url("./assets/img/Oval.svg");

html {
  font-size: 10px;
}

body {
  background: $bg-color;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: white;
}

html,
body {
  overflow-x: hidden;
  width: 100%;
}

a,
a:active,
a:visited,
a:focus {
  border-bottom: 1px solid $white;
  color: $grey;
}

a:hover {
  color: $white;
}

h1 {
  // margin-bottom: 5rem;
  letter-spacing: 1.98px;
  font-size: 10rem;
  font-weight: black;
}
@media (max-width: 1200px) {
  h1 {
    font-size: 10rem;
  }
}
@media (max-width: 992px) {
  h1 {
    font-size: 4em;
  }
}
@media (max-width: 768px) {
  h1 {
    font-size: 3em;
  }
}
// h1,
// h2,
// h3,
// h4 {
//   margin-top: 5rem;
// }

h1,
h2 {
  transition: 180ms linear;
}
h1:hover {
  color: $navy;
  transition: 120ms linear;
}
h2 + h2 {
  margin-top: 0.8rem;
}
.pl-11 {
  padding-left: 11rem;
}

// .Content {
//   margin: 0 auto;
//   max-width: 60rem;
//   // padding: 5rem 2rem;
//   position: relative;
//   text-align: center;
//   width: 100%;
// }

h1 {
  // font-size: 10rem;
  font-weight: 700;
}
h2 {
  font-size: 5.5em;
  font-weight: 700;
  color: white;
}
h3 {
  font-size: 1.8em;
  font-weight: 500;
  color: white;
}

.underline {
  border-bottom: 7px solid $white;
}
button {
  background: none;
  border: 1px solid rgba($black, 0.25);
  border-radius: 5rem;
  color: $white;
  cursor: pointer;
  padding: 1.1rem 2rem;
  transition: border 250ms;

  &:hover,
  &:focus {
    border: 1px solid $black;
  }
}
p {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  // font-size: 2.2em;
}

// ASSETS
button {
  font-family: "Share Tech Mono", monospace;
}
img {
  border: none;
}
.button-primary {
  background: #fff;
  color: #2005f5;
  padding: 15px 50px;
  border: 2px solid #2005f5;
  box-shadow: 7px 7px #2005f5;
  transition: 0.2s;
  border-radius: none;
}
.button-primary:hover {
  box-shadow: 11px 11px #2005f5;
  transition: 0.2s;
}
.button-secondary {
}
.button-tertiary {
}
.button-knockout {
}

.padding-s {
  padding: 20px 40px 20px 40px;
}
.spacing-m {
  padding-top: 10vh;
}
.StickyText-trigger {
  margin-top: 50vh;
}
.navbar-brand h2 {
  margin: 0px;
  font-family: "Raleway", sans-serif;
  font-weight: 800;
  font-size: 2.2em;
}
.navbar button {
  border: none;
}
.navbar-overlay-back {
  // background-color: #fff;
  // position: absolute;
  // width: 100vw;
  // height: 100vh;
}

.navbar-container ul {
  padding: 20px;
  // padding-left: 20px;
}
.navbar ul li {
  list-style: none;
  display: inline-block;
  border: none;
  margin-left: 50px;
}
.navbar ul li a,
.vertical-nav a {
  color: $white;
  border: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.navbar ul li a:hover,
.vertical-nav a:hover {
  // border-bottom: px $gray;
  // line-height: 60px;
  color: $black;
}
.navbar ul a {
  list-style: none;
  font-size: 1.4em;
  text-transform: uppercase;
  color: white;
  font-family: "Montserrat";
  font-weight: 500;
  text-align: left;
}
.navbar-toggler:focus {
  outline: 0;
}
.navbar-fixed-top.scrolled {
  background-color: #fff !important;
  transition: background-color 200ms linear;
}
.navbar-fixed-top.scrolled .nav-link {
  color: #555;
}
.vertical-nav {
  position: fixed;
  bottom: 0;
  right: 0;

  color: $white;
}
.vertical-nav a {
  transform: rotate(90deg);
}

// SECTIONS
.section {
  height: 100vh;
}
.hero {
  // height: 100vh;
  background-color: $yellow1;
  // border: 50px solid $white;
  z-index: -1;
}
.hero-container {
  position: relative;
  z-index: 0;
}
.hero-container h1 {
  padding-top: 45vh;
}
.hero-content {
  padding-top: 25vh;
  text-align: right;
}
.two {
  background-color: $purple;
}
.three {
  background-color: $navy;
}
.four {
  background-color: $black;
}

.col {
  border-left: 1px #d1d5fe solid;
  border-right: 1px #d1d5fe solid;
}

.underline div {
  display: inline-block;
}
.pushChar-left {
  padding-left: 90px;
  padding-bottom: 5px;
}
.pushChar-bottom {
  padding-bottom: 150px;
  padding-left: 10px;
  // padding-bottom: 5px;
}
.flex-center {
  // display: flex; // make us of Flexbox
  // align-items: left; // does vertically center the desired content
  // justify-content: left; // horizontally centers single line items
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  vertical-align: top;
  height: 100px;
}

.appear-text {
  // bottom: 0;
  // left: 0;
  // max-width: 54rem;
  opacity: 0;
  // padding: 0 2rem;
  // position: fixed;
  // width: 100%;
}
.padding-25 {
  padding-top: 25vh;
  padding-bottom: 0px;
}
.padding-r-25 {
  padding-right: 25vw;
}
.bottom {
  padding-top: 10vh;
  bottom: 100px;
}

.absolute-top {
  // position: absolute;
  // top: 0px;
  // left: 0px;
  float: left;
  // width: 100vw;
  z-index: 1;
}
.bg-divider {
  background-image: $bg-img;
  background-size: 100%;
  background-repeat: no-repeat;
}
.padding-20 {
  margin-top: 25vh;
}
